const mobileRegexp = /^(?!.*Google).*(Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini)/i;

// If not report and mobile redirect to unsupported_mobile page
if (!window.location.href.includes('control/reports') && navigator.userAgent.match(mobileRegexp)) {
  // Check if localstorage contains 'stay' key
  // If it does, do not redirect and proceed to the webapp
  if (!localStorage || !localStorage.getItem('stay')) {
    window.location.href = '/unsupported_mobile.html';
  }
}
